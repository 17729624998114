import React from "react";
import { Link, NavLink } from "react-router-dom";
import { RiLeafFill } from "react-icons/ri";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { menu } from "../data/menuItems";
import { useStateContext } from "../contexts/ContextProvider";
import logoImage from "../data/springview-logo.png";
import "../pages/Login/login.css";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, modules } = useStateContext();
  const [openLinks, setOpenLinks] = React.useState(false);

  const handleCloseSideBar = (isSubMenu) => {
    setOpenLinks(isSubMenu);
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-green-50  text-md m-2 bg-green-600 m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-green-950 dark:text-gray-200 dark:hover:text-black hover:bg-green-100 m-2";

  const activeSubMenuLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-green-50  text-md m-2 bg-green-600 m-2 dark:hover:text-black hover:bg-green-600 block w-full";
  const normalSubMenuLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-green-950 dark:text-gray-200 dark:hover:text-black hover:bg-green-100 m-2 dark:hover:text-black hover:bg-green-300 block w-full";

  const renderSubMenu = (link) => (
    <div className="pl-6" onClick={(e) => e.stopPropagation()}>
      <NavLink
        to={link.url + "/airbnb"}
        key="test1"
        onClick={() => handleCloseSideBar(true)}
        className={({ isActive }) =>
          isActive ? activeSubMenuLink : normalSubMenuLink
        }
      >
        <span className="capitalize">AirBnB Properties</span>
      </NavLink>
      <NavLink
        to={link.url + "/other"}
        key="test2"
        onClick={() => handleCloseSideBar(true)}
        className={({ isActive }) =>
          isActive ? activeSubMenuLink : normalSubMenuLink
        }
      >
        <span className="capitalize">Other Properties</span>
      </NavLink>
    </div>
  );

  const renderMenuItems = (item) => (
    <div key={item.id}>
      <p className="text-gray-400 m-3 mt-4 uppercase">{item.title}</p>
      {item.listItems.map((link) => {
        const isUrlInModules = modules.some(
          (module) => module.url === link.url
        );
        if (!isUrlInModules) return null;

        if (link.url === "/properties") {
          return (
            <div
              className="flex flex-col items-start gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-green-950 dark:text-gray-200 dark:hover:text-black hover:bg-green-100 m-2"
              key={link.id}
              onClick={() => setOpenLinks(!openLinks)}
            >
              <div className="flex items-center gap-5">
                {link.icon}
                <span className="capitalize">{link.title}</span>
                {openLinks ? <FiChevronUp /> : <FiChevronDown />}
              </div>
              {openLinks && renderSubMenu(link)}
            </div>
          );
        } else {
          return (
            <NavLink
              to={link.url}
              key={link.id}
              onClick={() => handleCloseSideBar(false)}
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              {link.icon}
              <span className="capitalize">{link.title}</span>
            </NavLink>
          );
        }
      })}
    </div>
  );

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img
                src={logoImage} // Replace with your logo path
                alt="Springview Cleaning"
                className="max-w-xxs mx-auto"
              />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <GrFormClose />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">{menu.map(renderMenuItems)}</div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
