import React, { useEffect, useState } from "react";
import { Container, CssBaseline, Button, TextField } from "@mui/material";
import { Link, Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom"; // Import Link from react-router-dom
import logoImage from "../../data/springview-logo.png";
import "./login.css";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../contexts/ContextProvider";
import { enqueueSnackbar } from "notistack";

function SetPassword() {
  const { token, setUser, setToken } = useStateContext();
  const [isLoggingIn, setIsLoggingIn] = useState();
  const [tokenParam] = useSearchParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
    token: tokenParam.get("token"),
  });
  const [errors, setErrors] = useState({
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Basic input validation
    let validationPassed = true;
    const updatedErrors = { ...errors };

    let passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/i;

    if (!formData.password) {
      updatedErrors.password = "Password is required";
      validationPassed = false;
    } else if (!passwordRegex.test(formData.password)) {
      updatedErrors.password =
        "Password should be 8 characters minimum length, should contain at least 1 lower case, 1 upper case, 1 number, and 1 special character";
      validationPassed = false;
    } else {
      updatedErrors.password = "";
    }

    if (formData.password_confirmation !== formData.password) {
      updatedErrors.password_confirmation = "Password do not match";
      validationPassed = false;
    } else {
      updatedErrors.password_confirmation = "";
    }

    setErrors(updatedErrors);

    if (validationPassed) {
      setIsLoggingIn(true);
      // Handle form submission here
      axiosClient
        .post("set-password", formData)
        .then(({ data }) => {
            setUser(data.user);
            setToken(data.token);
            navigate("/dashboard")
        })
        .catch((err) => {
          setIsLoggingIn(false);
          const response = err.response;

          if (
            response &&
            (response.status === 422 || response.status === 500)
          ) {
            if (response.data.errors) {
              enqueueSnackbar(response.data.errors.token[0], {
                variant: "error",
              });
            } else {
              enqueueSnackbar(response.data.message, {
                variant: "error",
              });
            }
          }
        });
    }
  };


  return (
    <div className="animated fadeInDown">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className="min-h-screen flex flex-col items-center justify-center">
          <div className="bg-white dark:bg-gray-800 p-8 rounded shadow-lg w-full max-w-md">
            <img
              src={logoImage} // Replace with your logo path
              alt="Springview Cleaning"
              className="max-w-xxs -mt-20 mb-8 mx-auto"
            />

            <h1 className="text-4xl text-center text-emerald-900 font-medium mb-4">
              Set Password
            </h1>
            <h1 className="text-sm text-emerald-700 text-center mb-1">
              To continue using your account, please set a password
            </h1>
            <form onSubmit={handleFormSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Password Confirmation"
                type="password"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleInputChange}
                error={!!errors.password_confirmation}
                helperText={errors.password_confirmation}
              />
              <div className="pt-8">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="success"
                  disabled={isLoggingIn}
                >
                  Log in
                </Button>
              </div>
            </form>
            <p className="mt-2 text-green-700 text-right cursor-pointer hover:text-red-700">
              {/* Use react-router-dom's Link component to redirect */}
              {!isLoggingIn ? (
                <Link to="/login">Back to Login </Link>
              ) : (
                <span>Back to Login</span>
              )}
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SetPassword;
