const orderIdHeaderTemplate = (props) => {
  return <span>{props.headerText}</span>;
};

export const usersGrid = [
  {
    field: "name",
    headerText: "Name",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "email",
    headerText: "Email",
    width: "170",
    textAlign: "Left",
  },
  {
    field: "role",
    headerText: "Role",
    width: "120",
    textAlign: "Left",
  },
  {
    field: "status",
    headerText: "Status",
    width: "135",
    textAlign: "Left",
  },
];
