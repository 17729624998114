import { useRef } from "react";
import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  ScheduleComponent,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Button, CircularProgress } from "@mui/material";
import { GoSync } from "react-icons/go";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import axiosClient from "../axios-client";
import { enqueueSnackbar } from "notistack";

const JobListSchedule = ({ listing }) => {
  const [resourceData, setResourceData] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);

  var addEventStart = null;
  var addEventEnd = null;
  var addEventNotes = null;

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };

  const getCleanerEvents = () => {
    axiosClient
      .get(`/cleaner-events`)
      .then(({ data }) => {
        setResourceData(
          data.events.map((event) => {
            let isReservationEvent = false;

            // only show accepted reservations
            if (event.type === "reservation" && event.status === "accepted") {
              isReservationEvent = true;
            }
            return {
              Id: event.id,
              Subject: event.subject,
              StartTime: event.start_date,
              EndTime: event.end_date,
              Duration: isReservationEvent
                ? new Date(event.start_date).toLocaleDateString(
                    undefined,
                    options
                  ) +
                  " - " +
                  new Date(event.end_date).toLocaleDateString(
                    undefined,
                    options
                  )
                : null,
              BookedAt: isReservationEvent ? event.booked_at : null,
              Occupants: isReservationEvent ? event.occupants : null,
              GuestDetails: isReservationEvent ? event.guest_details : null,
              Notes: !isReservationEvent ? event.notes : null,
              Type: event.type,
              Status:
                event.status.charAt(0).toUpperCase() + event.status.slice(1),
            };
          })
        );
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getCleanerEvents();
  }, []);

  const scheduleObj = useRef(null);
  const eventSettings = { dataSource: resourceData };

  const buttonClickActions = (e) => {
    let eventData = {};
    const action = e.target.id;

    const getSlotData = () => {
      const addObj = {};
      addObj.Id = scheduleObj.current.getEventMaxID();
      addObj.Subject = "Cleaning Schedule";
      addObj.StartTime = new Date(addEventStart);
      addObj.EndTime = new Date(addEventEnd);
      addObj.Notes = addEventNotes;
      addObj.Status = "PENDING";
      addObj.Type = "job";
      return addObj;
    };
    switch (action) {
      case "add":
        eventData = getSlotData();
        let payload = {
          start_date: eventData.StartTime.toISOString(),
          end_date: eventData.EndTime.toISOString(),
          notes: eventData.Notes,
          listing_id: listing.id,
        };
        axiosClient
          .post("/jobs/add", payload)
          .then(({ data }) => {
            enqueueSnackbar(data.message, {
              variant: "success",
            });
            scheduleObj.current.addEvent(eventData);
          })
          .catch((err) => {
            const response = err.response;
            enqueueSnackbar(response.data.message, {
              variant: "error",
            });
          });
        break;
      case "edit":
      case "edit-series":
      case "delete":
      case "delete-series":
      case "more-details":
      default:
        break;
    }
    scheduleObj.current.closeQuickInfoPopup();
  };

  const handleStartTimeChange = (e) => {
    addEventStart = +e.value;
  };

  const handleEndTimeChange = (e) => {
    addEventEnd = +e.value;
  };

  const handleNotesChange = (e) => {
    addEventNotes = e.target.value;
  };

  const header = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div
            className="flex items-center justify-between p-2 border border-gray-300 rounded text-white"
            style={{ backgroundColor: "#4ac299" }}
          >
            <p className="flex-1">Assign Cleaning Job</p>
            <button
              id="close"
              className="e-close e-close-icon e-icons"
              title="CLOSE"
              onClick={buttonClickActions.bind(this)}
            />
          </div>
        ) : (
          <div className="flex items-center justify-between p-2 border border-gray-300 rounded text-white ">
            <p className="flex-1">{props.Subject}</p>
            <button
              id="close"
              className="e-close e-close-icon e-icons"
              title="CLOSE"
              onClick={buttonClickActions.bind(this)}
            />
          </div>
        )}
      </div>
    );
  };

  const content = (props) => {
    var startTime = new Date(props.StartTime);
    var endTime = new Date(props.EndTime);
    addEventStart = +startTime;
    addEventEnd = +endTime;

    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-content e-template">
            <form className="e-schedule-form">
              <div>
                <span>Start</span>
                <DateTimePickerComponent
                  format="dd/MM/yy hh:mm a"
                  className="startTime e-field e-input"
                  name="StartTime"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />
              </div>
              <div>
                <span>End</span>
                <DateTimePickerComponent
                  format="dd/MM/yy hh:mm a"
                  className="endTime e-field e-input"
                  name="EndTime"
                  value={endTime}
                  onChange={handleEndTimeChange}
                />
              </div>
              <div>
                <textarea
                  className="notes e-field e-input"
                  type="text"
                  name="Notes"
                  placeholder="Notes"
                  style={{ resize: "none" }}
                  onChange={handleNotesChange}
                />
              </div>
            </form>
          </div>
        ) : (
          <div className="">
            {props.Type === "reservation" && (
              <div className="">
                {props.GuestDetails.phone !== null ? (
                  <div className="mt-2">Phone: {props.GuestDetails.phone}</div>
                ) : (
                  ""
                )}
                {props.GuestDetails.email !== null ? (
                  <div className="mt-2">Email: {props.GuestDetails.email}</div>
                ) : (
                  ""
                )}
                {props.ReservationCode !== null ? (
                  <div className="mt-2">Duration: {props.Duration}</div>
                ) : (
                  ""
                )}
                {props.ReservationCode !== null ? (
                  <div className="mt-2">Booked at: {props.BookedAt}</div>
                ) : (
                  ""
                )}
                {props.OccupancyDetails !== null ? (
                  <div className="mt-2">Occupants: {props.Occupants}</div>
                ) : (
                  ""
                )}
              </div>
            )}
            {props.Type === "job" && (
              <div className="">
                {props.Status !== null ? (
                  <div className="mt-2">Status: {props.Status}</div>
                ) : (
                  ""
                )}
                {props.Notes !== null ? (
                  <div className="mt-2">Notes: {props.Notes}</div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const footer = (props) => {
    return (
      <div>
        {props.elementType === "cell" ? (
          <div className="e-cell-footer">
            <div className="right-button">
              <Button
                className="bottom-3 top-1"
                size="small"
                color="secondary"
                variant="contained"
                id="add"
                onClick={buttonClickActions.bind(this)}
              >
                Add
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const quickInfoTemplates = {
    header: header.bind(this),
    content: content.bind(this),
    footer: footer.bind(this),
  };

  const onEventRendered = (args) => {
    switch (args.data.Type) {
      case "reservation":
        args.element.style.backgroundColor = "#4ac299";
        break;

      // 3 TODO: look for correct colors
      case "job":
        if (args.data.Status === "pending")
          args.element.style.backgroundColor = "#EEDE01";
        else if (args.data.Status === "accepted")
          args.element.style.backgroundColor = "#e3165b";
        else if (args.data.Status === "declined")
          args.element.style.backgroundColor = "#e3165b";
        else if (args.data.Status === "Completed")
          args.element.style.backgroundColor = "#F170B0";
        else args.element.style.backgroundColor = "#D0BA2D";
        // args.element.style.backgroundColor = "#e3165b";
        // else args.element.style.backgroundColor = "#D0BA2D";

        break;
      default:
        break;
    }
  };

  const onPopupOpen = (args) => {
    let isEmptyCell =
      args.target !== undefined &&
      (args.target.classList.contains("e-work-cells") ||
        args.target.classList.contains("e-header-cells"));

    if (args.type === "QuickInfo" && isEmptyCell) {
      args.cancel = true;
    }

    if (args.type === "Editor") {
      args.cancel = true;
    }
  };

  return (
    <>
      <ScheduleComponent
        id="schedule"
        currentView="Month"
        ref={scheduleObj}
        width="100%"
        height="650px"
        selectedDate={new Date()}
        eventSettings={eventSettings}
        quickInfoTemplates={quickInfoTemplates}
        eventRendered={onEventRendered}
        popupOpen={onPopupOpen}
      >
        <ViewsDirective>
          {["Month"].map((item) => (
            <ViewDirective key={item} option={item} />
          ))}
        </ViewsDirective>
        <Inject services={[Month]} />
      </ScheduleComponent>
    </>
  );
};

export default JobListSchedule;
