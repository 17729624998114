import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../axios-client";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import ImageUpload from "../../components/ImageUpload";

const FinishJob = ({ setOpenFinishJobModal, job, setJobData }) => {
  const [didSubmit, setDidSubmit] = useState(false);
  const [items, setItems] = useState([]);
  const [images, setImages] = useState([]);
  const inventoryItemRefs = useRef([]);
  const [inventoryItems, setInventoryItems] = useState({});
  const inventoryItemQuantityRefs = useRef([]);

  const handleSubmit = () => {
    setDidSubmit(true);
    const inventoryPayload = items.map((item) => ({
      inventoryItem: item.selectValue,
      quantity: item.quantityValue,
    }));

    const formData = new FormData();

    // append image files from list
    images.forEach((image, index) => {
      formData.append(`images[${index}]`, image.file);
    });

    // append inventory key-value paris as json string
    inventoryPayload.forEach((item, index) => {
      formData.append(`inventory[${index}]`, JSON.stringify(item));
    });

    axiosClient
      .post(`/jobs/${job.id}/finish`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        enqueueSnackbar(data.message, {
          variant: "success",
        });
        let params = {
          filter: "pending-jobs",
        };
        axiosClient
          .get("/jobs", {
            params: params,
          })
          .then(({ data }) => {
            setJobData(data.jobs);
            setItems([]);
            setImages([]);
            setDidSubmit(false);
            setOpenFinishJobModal(false);
          })
          .catch((err) => {
            const response = err.response;
            enqueueSnackbar(response.data.message, {
              variant: "error",
            });
          });
      })
      .catch((err) => {
        setDidSubmit(false);

        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const addItem = () => {
    const newItem = { selectValue: "", quantityValue: 0 }; // Initialize quantity to 0
    setItems([...items, newItem]);
    inventoryItemRefs.current.push(React.createRef());
    inventoryItemQuantityRefs.current.push(React.createRef());
  };

  const handleSelectChange = (index, e) => {
    const newItems = [...items];
    newItems[index].selectValue = e.target.value;
    setItems(newItems);
  };

  const handleQuantityChange = (index, value) => {
    const newItems = [...items];
    newItems[index].quantityValue = value;
    setItems(newItems);
  };

  const incrementQuantity = (index) => {
    const newItems = [...items];
    newItems[index].quantityValue += 1;
    setItems(newItems);
  };

  const decrementQuantity = (index) => {
    const newItems = [...items];
    if (newItems[index].quantityValue > 0) {
      newItems[index].quantityValue -= 1;
      setItems(newItems);
    }
  };

  const deleteItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    inventoryItemRefs.current.splice(index, 1);
    inventoryItemQuantityRefs.current.splice(index, 1);
    setItems(newItems);
  };

  const updateImageList = (newImages) => {
    setImages(newImages);
  };

  useEffect(() => {
    axiosClient.get("/inventory-items").then(({ data }) => {
      setInventoryItems(data.inventory_items);
    });
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
      <div className="relative bg-white w-full max-w-7xl rounded-lg shadow-lg">
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              Job ID #{job.id} - Report Submission
            </h2>
            <button
              disabled={didSubmit}
              onClick={() => setOpenFinishJobModal(false)}
              className={`text-gray-500 ${
                !didSubmit ? "hover:text-black" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="max-h-[60vh] overflow-y-auto p-6">
          <p className="text-gray-400 font-semibold tracking-tight text-slate-400 pl-4 pt-4 text-center">
            To show that the job is done, just send in a report to confirm it's
            finished.
          </p>
          <p className="text-gray-700 font-semibold tracking-tight text-slate-900 pl-4 pt-4">
            Upload Photos
          </p>

          {/* Image Upload Component */}
          <div className="flex pt-4 pl-4">
            <ImageUpload updateImageList={updateImageList} />
          </div>

          <p className="text-gray-700 font-semibold tracking-tight text-slate-900 pl-4 pt-4">
            Inventory
          </p>
          <div className="flex pt-4 pl-4">
            <TooltipComponent
              content="Add inventory item"
              position="BottomCenter"
            >
              <Button
                size="small"
                variant="outlined"
                color="success"
                style={{ display: "flex", alignItems: "center" }}
                onClick={addItem}
                startIcon={<MdOutlineAdd />}
              >
                Add Item
              </Button>
            </TooltipComponent>
          </div>

          {items.map((item, index) => (
            <div className="flex p-4" key={index}>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={6} md={4}>
                  <TextField
                    select
                    label="Item"
                    size="small"
                    fullWidth
                    ref={inventoryItemRefs.current[index]}
                    value={item.selectValue}
                    onChange={(e) => handleSelectChange(index, e)}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Set the maximum height for the menu
                            overflowY: "auto", // Enable vertical scrolling
                          },
                        },
                      },
                    }}
                  >
                    {inventoryItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8} sm={6} md={5}>
                  <div className="flex items-center gap-8">
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => decrementQuantity(index)}
                    >
                      <AiOutlineMinus />
                    </IconButton>
                    <TextField
                      style={{ width: "60px" }}
                      type="number"
                      size="small"
                      ref={inventoryItemQuantityRefs.current[index]}
                      value={item.quantityValue}
                      onChange={(e) =>
                        handleQuantityChange(index, e.target.value)
                      }
                      placeholder="Quantity"
                    />
                    <IconButton
                      color="success"
                      size="small"
                      onClick={() => incrementQuantity(index)}
                    >
                      <AiOutlinePlus />
                    </IconButton>
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => deleteItem(index)}
                    >
                      <FaRegTrashAlt />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setOpenFinishJobModal(false)}
            disabled={didSubmit}
          >
            Back
          </Button>
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="success"
            onClick={handleSubmit}
            disabled={didSubmit}
            startIcon={
              didSubmit ? <CircularProgress size={12} color="inherit" /> : ""
            }
          >
            Submit report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FinishJob;
