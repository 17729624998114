import React, { useState } from "react";
import { Container, CssBaseline, Button, TextField } from "@mui/material";
import { Link, Link as RouterLink } from "react-router-dom"; // Import Link from react-router-dom
import logoImage from "../../data/springview-logo.png";
import "./login.css";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import { AiOutlineCheckCircle } from "react-icons/ai";

function ForgotPassword() {
  const [didSubmit, setDidSubmit] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Basic input validation
    let validationPassed = true;
    const updatedErrors = { ...errors };

    if (!formData.email) {
      updatedErrors.email = "Email is required";
      validationPassed = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      updatedErrors.email = "Invalid email format";
      validationPassed = false;
    } else {
      updatedErrors.email = "";
    }

    setErrors(updatedErrors);

    if (validationPassed) {
      setDidSubmit(true);

      axiosClient
        .post("forgot-password", formData)
        .then(({ data }) => {
          setShowSuccessMessage(data.message);
          // setShowForm(false);
          // setShowMessage(true);
        })
        .catch((err) => {
          setDidSubmit(false);
          const response = err.response;
          if (response && response.status === 422) {
            if (response.data.errors) {
              setErrors(response.data.errors);
            } else {
              enqueueSnackbar(response.data.message, {
                variant: "error",
              });
            }
          }
        });
    }
  };

  return (
    <div className="animated fadeInDown">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className="min-h-screen flex flex-col items-center justify-center">
          <div className="bg-white dark:bg-gray-800 p-8 rounded shadow-lg w-full max-w-md">
            <img
              src={logoImage} // Replace with your logo path
              alt="Springview Cleaning"
              className="max-w-xxs -mt-20 mb-8 mx-auto"
            />
            {!showSuccessMessage && (
              <div>
                <h1 className="text-3xl text-center text-emerald-900 font-medium mb-4">
                  Forgot Password
                </h1>

                <h1 className="text-sm text-emerald-700 text-center mb-1">
                  A link will be sent to your email to reset your password
                </h1>
                <form onSubmit={handleFormSubmit}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <div className="pt-8">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={didSubmit}
                      color="success"
                    >
                      Send Link
                    </Button>
                  </div>
                </form>
              </div>
            )}
            {showSuccessMessage && (
              <div className="flex items-center justify-center space-x-2 animated fadeInDown">
                <AiOutlineCheckCircle className="text-emerald-700"/>
                <h1 className="text-base text-center text-emerald-700 text-center flex">
                  {showSuccessMessage}
                </h1>
              </div>
            )}
            <p className="mt-6 text-green-700 text-right cursor-pointer hover:text-red-700">
              {/* Use react-router-dom's Link component to redirect */}
              <Link to="/login">Back to Login </Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ForgotPassword;
