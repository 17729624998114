import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [token, _setToken] = useState(localStorage.getItem("ACCESS_TOKEN"));
  const [user, setUser] = useState({});
  const [modules, setModules] = useState([]);
  const [role, setRole] = useState({});
  const [isAdmin, _setIsAdmin] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
    }
  };

  const handleClick = (clicked) => {
    setIsClicked((prevState) => ({
      ...prevState,
      [clicked]: !prevState[clicked], // Toggling the value
    }));
  };

  const setIsAdmin = (role) => {
    if (role.role_name === "Administrator") {
      _setIsAdmin(true);
    } 
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        screenSize,
        setScreenSize,
        token,
        setToken,
        user,
        setUser,
        isLoading,
        setIsLoading,
        modules,
        setModules,
        role,
        setRole,
        isAdmin,
        setIsAdmin,
        handleClick,
        isSyncing,
        setIsSyncing,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
