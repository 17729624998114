import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { BsFillPlayFill, BsStopFill } from "react-icons/bs";
import { PiClipboardText } from "react-icons/pi";
import { FaStickyNote } from "react-icons/fa";
import { Header } from "../../components";
import { jobsGrid } from "../../data/jobsData";
import axiosClient from "../../axios-client";
import { useJobListStateContext } from "../../contexts/JobListContextProvider";
import { enqueueSnackbar } from "notistack";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import JobListSchedule from "../../components/JobListSchedule";
import { MdExpandMore } from "react-icons/md";
import FinishJob from "../Modals/FinishJob";
import { useStateContext } from "../../contexts/ContextProvider";
import AddNote from "../Modals/AddNote";
import ViewJobReport from "../Modals/ViewJobReport";

const Jobs = () => {
  const {
    jobData,
    setJobData,
    openFinishJobModal,
    setOpenFinishJobModal,
    selectedJob,
    setSelectedJob,
    openAddNoteModal,
    setOpenAddNoteModal,
    openReportModal,
    setOpenReportModal,
    selectedJobReport,
    setSelectedJobReport,
  } = useJobListStateContext();

  const { isAdmin } = useStateContext();

  const [jobListFilter, setJobListFilter] = useState("pending-jobs");
  const [isExpanded, setIsExpanded] = useState(isAdmin);

  const ActionButton = ({ title, customFunc, icon, color }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-lg rounder-full p-3 hover:bg-light-gray"
      >
        <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );

  const userListButtons = (props) => {
    return (
      <div className="flex items-center justify-center gap-2">
        {/* if job is completed, view report */}
        {props.status === "Completed" && (
          <ActionButton
            title="View Report"
            customFunc={() => handleView(props)}
            icon={<PiClipboardText />}
            color="#4d4c5c"
          />
        )}
        {/* if job is not yet started but is active */}
        {props.status === "Pending" && !isAdmin && (
          <ActionButton
            title="Start Cleaning"
            customFunc={() => handleStart(props)}
            icon={<BsFillPlayFill />}
            color="#4AC299"
          />
        )}
        {/* trigger end of job report */}
        {props.status === "Started" && !isAdmin && (
          <ActionButton
            title="Finish Cleaning"
            customFunc={() => handleFinish(props)}
            icon={<BsStopFill />}
            color="#E20E3A"
          />
        )}
        {/* trigger end of job report */}
        {(props.status === "Pending" || props.status === "Scheduled") &&
          isAdmin && (
            <ActionButton
              title="Add Cleaning Notes"
              customFunc={() => handleAddNote(props)}
              icon={<FaStickyNote />}
              color="#FEED0B"
            />
          )}
      </div>
    );
  };

  const handleView = (data) => {
    axiosClient
      .get(`/reports/jobs/${data.report_id}`)
      .then(({ data }) => {
        setSelectedJobReport(data.cleaning_report);
        setOpenReportModal(true);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const handleStart = (data) => {
    axiosClient
      .put(`/jobs/${data.id}/start`)
      .then(({ data }) => {
        enqueueSnackbar(data.message, {
          variant: "success",
        });
        let params = {
          filter: "pending-jobs",
        };
        axiosClient
          .get("/jobs", {
            params: params,
          })
          .then(({ data }) => {
            setJobData(data.jobs);
          })
          .catch((err) => {
            const response = err.response;
            enqueueSnackbar(response.data.message, {
              variant: "error",
            });
          });
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const handleFinish = (data) => {
    setSelectedJob(data);
    setOpenFinishJobModal(true);
  };

  const handleAddNote = (data) => {
    setSelectedJob(data);
    setOpenAddNoteModal(true);
  };

  useEffect(() => {
    let params = {
      filter: "pending-jobs",
    };
    axiosClient
      .get("/jobs", {
        params: params,
      })
      .then(({ data }) => {
        setJobData(data.jobs);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  }, [setJobData]);

  const filters = [
    {
      value: "pending-jobs",
      label: "Pending Jobs",
    },
    {
      value: "completed-jobs",
      label: "Completed Jobs",
    },
    {
      value: "future-jobs",
      label: "Future Jobs",
    },
  ];

  const handleFilterChange = (event) => {
    setJobListFilter(event.target.value);
    let params = {
      filter: event.target.value,
    };

    axiosClient
      .get("/jobs", {
        params: params,
      })
      .then(({ data }) => {
        setJobData(data.jobs);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Lists" title="Jobs" />
      <div className="flex p-4">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                View Jobs
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={8} sm={4} md={2}>
                    <TextField
                      select // tell TextField to render select
                      label="Filter"
                      size="small"
                      value={jobListFilter}
                      fullWidth
                      onChange={handleFilterChange}
                    >
                      {filters.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <div className="pt-4">
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={16}>
                      <GridComponent
                        dataSource={jobData}
                        width="auto"
                        allowPaging
                        allowSorting
                        allowTextWrap
                        pageSettings={{ pageSize: 5 }}
                        statelessTemplates={["directiveTemplates"]}
                        allowSelection={false}
                      >
                        <ColumnsDirective>
                          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                          {jobsGrid.map((item, index) => (
                            <ColumnDirective key={index} {...item} />
                          ))}
                          <ColumnDirective
                            key="actions"
                            headerText="Actions"
                            width="100"
                            textAlign="Center"
                            template={userListButtons}
                          />
                        </ColumnsDirective>
                        <Inject services={[Page, Toolbar, Sort]} />
                      </GridComponent>
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
      {!isAdmin && (
        <div className="flex p-4">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={14}>
              <JobListSchedule listing={null} />
            </Grid>
          </Grid>
        </div>
      )}

      {openFinishJobModal && (
        <FinishJob
          setOpenFinishJobModal={setOpenFinishJobModal}
          job={selectedJob}
          setJobData={setJobData}
        />
      )}

      {openAddNoteModal && (
        <AddNote
          setOpenAddNoteModal={setOpenAddNoteModal}
          job={selectedJob}
          setJobData={setJobData}
          jobListFilter={jobListFilter}
        />
      )}

      {openReportModal && (
        <ViewJobReport
          setOpenJobReportModal={setOpenReportModal}
          report={selectedJobReport}
        />
      )}
    </div>
  );
};
export default Jobs;
