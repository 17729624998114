import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React, { useEffect } from "react";
import { FiSettings } from "react-icons/fi";
import { useStateContext } from "../contexts/ContextProvider";
import { Navbar, Sidebar } from "../components";
import { Navigate, Outlet } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import "./defaultLayout.css";
import axiosClient from "../axios-client";

const MainContent = () => {
  const { activeMenu } = useStateContext();

  return (
    <div>
      <div className="flex relative dark:bg-main-dark-bg">
        {/* <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
          <TooltipComponent content="Settings" position="Top">
            <button
              type="button"
              className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
              style={{ background: "#16a34a", borderRadius: "50%" }}
            >
              <FiSettings />
            </button>
          </TooltipComponent>
        </div> */}
        {activeMenu ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}
        <div
          className={`dark:bg-main-bg bg-main-bg w-full
                  ${activeMenu ? " min-h-screen md:ml-72" : "flex-2"}`}
        >
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
            <Navbar />
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingPage = () => (
  <div className="loading">
    <PuffLoader color="#16a34a" />
  </div>
);

const DefaultLayout = () => {
  const {
    isLoading,
    setIsLoading,
    user,
    setUser,
    setRole,
    setModules,
    token,
    setIsAdmin,
  } = useStateContext();

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get("/user")
      .then(({ data }) => {
        setUser(data);
        axiosClient.get("/modules").then(({ data }) => {
          setModules(data.modules);
          setRole(data.role);
          setIsAdmin(data.role);
          setIsLoading(false);
        });
      })
      .catch((err) => {
        console.log("no token");
      });
  }, []);

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <div>{isLoading ? <LoadingPage /> : <MainContent />}</div>;
};

export default DefaultLayout;
