const options = {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const gridPropertyName = (props) => (
  <div className="flex flex-col">
    <p className="font-semibold font-sm">{props.property_name}</p>
    <p className="font-xs text-pink-500">{props.address}</p>
  </div>
);

const gridSchedule = (props) => (
  <div className="flex flex-col overflow-auto">
    <p className="font-semibold font-sm">
      {new Date(props.date_from).toLocaleDateString(undefined, options)} -{" "}
      {new Date(props.date_to).toLocaleDateString(undefined, options)}
    </p>
  </div>
);

export const jobsGrid = [
  {
    field: "id",
    headerText: "Job ID",
    width: "80",
    textAlign: "Left",
  },
  {
    field: "property_name",
    headerText: "Property",
    width: "150",
    template: gridPropertyName,
    textAlign: "Left",
  },
  {
    field: "schedule",
    headerText: "Schedule",
    width: "150",
    template: gridSchedule,
    textAlign: "Left",
  },
  {
    field: "cleaner",
    headerText: "Assigned To",
    width: "130",
    textAlign: "Left",
  },
  {
    field: "note",
    headerText: "Notes",
    width: "130",
    textAlign: "Left",
  },
  {
    field: "status",
    headerText: "Status",
    width: "115",
    textAlign: "Left",
  },
];

export const jobReportsGrid = [
  {
    field: "id",
    headerText: "#",
    width: "80",
    textAlign: "Left",
  }, 
  {
    field: "cleaning_job_id",
    headerText: "Job ID",
    width: "80",
    textAlign: "Left",
  },
  {
    field: "property_name",
    headerText: "Property",
    width: "150",
    template: gridPropertyName,
    textAlign: "Left",
  },
  {
    field: "schedule",
    headerText: "Schedule",
    width: "180",
    template: gridSchedule,
    textAlign: "Left",
  },
  {
    field: "cleaner",
    headerText: "Assigned To",
    width: "80",
    textAlign: "Left",
  }
];
