import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const ViewUser = ({ setOpenViewModal, user }) => {
  const cardData = [
    {
      title: "Card 1",
      content: "Content for Card 1",
    },
    {
      title: "Card 2",
      content: "Content for Card 2",
    },
    {
      title: "Card 3",
      content: "Content for Card 3",
    },
    // Add more card data as needed
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
      <div className="bg-white w-full max-w-6xl max-h-6xl rounded-lg shadow-lg overflow-y-auto">
        {/* Modal Header */}
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              View User
            </h2>
            <button
              InputProps={{
                readOnly: true,
              }}
              onClick={() => setOpenViewModal(false)}
              className={`text-gray-500`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Modal Body */}
        <p className="text-gray-700 font-semibold tracking-tight text-slate-900 pl-4 pt-4">
          Basic Information
        </p>

        <div className="flex p-4">
          <Grid container spacing={3}>
            {/* Row 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Email"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.email}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                label="Is Verified?"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.is_email_verified}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className="flex p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="First Name"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.first_name}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Last Name"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.last_name}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                label="Status"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.status}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className="flex p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Role"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.roleDetails.role_name}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Last Login"
                InputProps={{
                  readOnly: true,
                }}
                defaultValue={user.last_login}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>

        {user.roleDetails.role_name === "Property Owner" && (
          <>
            <p className="text-gray-700 font-semibold tracking-tight text-slate-900 pl-4 pt-4">
              Assigned Properties
            </p>
            <Grid container spacing={2} className="p-4">
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{card.title}</Typography>
                      <Typography variant="body2">{card.content}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {user.roleDetails.role_name === "Cleaner" && (
          <>
            <p className="text-gray-700 font-semibold tracking-tight text-slate-900 pl-4 pt-4">
              Recent Jobs
            </p>
            <Grid container spacing={2} className="p-4">
              {cardData.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{card.title}</Typography>
                      <Typography variant="body2">{card.content}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setOpenViewModal(false)}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
