import { Button, Typography, Tabs, Tab, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  FaRegBuilding,
  FaMapMarkerAlt,
  FaSignOutAlt,
  FaUserClock,
} from "react-icons/fa";
import { MdLuggage } from "react-icons/md";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import axiosClient from "../../axios-client";

function ListingTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ListingTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const theme = createTheme({
  palette: {
    primary: green,
    secondary: {
      main: "#4ac299",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#FFF",
    },
  },
});

const ReportPaper = styled(Paper)(({ theme, height }) => ({
  width: 480,
  height: height,
  ...theme.typography.body2,
  textAlign: "center",
}));

const ViewJobReport = ({ setOpenJobReportModal, report }) => {
  const [value, setValue] = useState(0);
  const [openLightBox, setOpenLightBox] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalClose = () => {
    setSlideIndex(0);
    setOpenLightBox(false);
    setOpenJobReportModal(false);
  };

  const openLightbox = (index) => {
    setSlideIndex(index);
    setOpenLightBox(true);
  };

  const tabContentStyles = {
    transition: "opacity 0.3s ease-in-out",
    opacity: value === 0 ? 1 : 0, // Show "Property Details" tab content if it's selected
  };

  const exportToPDF = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/reports/jobs/${report.id}/export-pdf`;
    window.open(url, "_blank");
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
        <div className="relative bg-white w-full max-w-2xl rounded-lg shadow-lg">
          {/* Modal Header */}
          {/* Header */}
          <div className="bg-gray-100 py-2 px-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
                View Cleaning Report No. {report.id}
              </h2>
              <button
                InputProps={{
                  readOnly: true,
                }}
                onClick={handleModalClose}
                className={`text-gray-500`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Modal Body */}
          <div className="max-h-[80vh] overflow-y-auto p-6">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="Report Tabs"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: green,
                    },
                  }}
                >
                  <Tab label="Report Details" />
                  <Tab label="Inventory Details" />
                </Tabs>
              </Box>

              {/* REPORT DETAILS */}
              {value === 0 && (
                <div className="flex items-center justify-center">
                  <TabPanel
                    key={0}
                    value={value}
                    index={0}
                    style={tabContentStyles}
                  >
                    <div className="flex px-5 py-5">
                      <div className="flex-1">
                        {/* Property Information */}
                        <Stack direction="row" spacing={1} className="pb-4">
                          <ReportPaper
                            square={false}
                            height={165}
                            elevation={4}
                          >
                            <div
                              className="flex items-center bg-stone-200 justify-start h-screen p-4 font-medium gap-2"
                              style={{ height: "50px" }}
                            >
                              <FaRegBuilding />
                              Property Information
                            </div>
                            <div className="body">
                              <div className="flex items-center p-4 gap-2">
                                <img
                                  className="box-full w-20 h-20"
                                  src={report.property_details.image}
                                  alt="property"
                                />
                                <div className="text-left">
                                  <div className="flex flex-col gap-y-2">
                                    <p className="font-semibold font-sm">
                                      {report.property_details.name}
                                    </p>
                                    <div className="flex justify-start gap-1">
                                      <FaMapMarkerAlt />
                                      <p className="font-xs">
                                        <span
                                          className="overflow-hidden overflow-ellipsis whitespace-nowrap"
                                          title={
                                            report.property_details.address
                                          }
                                        >
                                          {report.property_details.address}
                                        </span>
                                      </p>
                                    </div>
                                    <p className="font-semibold font-sm">
                                      {report.property_details.details}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ReportPaper>
                        </Stack>

                        {/* Reservation Details*/}
                        <Stack direction="row" spacing={1} className="pb-4">
                          <ReportPaper
                            square={false}
                            height={140}
                            elevation={4}
                          >
                            <div
                              className="flex items-center bg-stone-200 justify-start h-screen p-4 font-medium gap-2"
                              style={{ height: "50px" }}
                            >
                              <MdLuggage style={{ fontSize: "15px" }} />
                              Job Booked for
                            </div>
                            <div className="body p-4">
                              <div className="text-left">
                                <div className="flex flex-col gap-y-4">
                                  <span className="flex gap-1">
                                    <p className="font-semibold font-sm">
                                      {report.reservation_details.name}
                                    </p>
                                    - Airbnb Reservation
                                  </span>

                                  <div className="flex justify-start gap-1">
                                    <FaUserClock style={{ fontSize: "18px" }} />
                                    <span className="flex font-xs gap-1">
                                      {report.reservation_details.total_nights}{" "}
                                      night(s): Checked in on{" "}
                                      <p className="font-semibold">
                                        {report.reservation_details.date_from}
                                      </p>{" "}
                                      Checked in on{" "}
                                      <p className="font-semibold">
                                        {report.reservation_details.date_to}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ReportPaper>
                        </Stack>

                        {/* Cleaning Details */}
                        <Stack direction="row" spacing={1} className="pb-4">
                          <ReportPaper
                            square={false}
                            height={260}
                            elevation={4}
                          >
                            <div
                              className="flex items-center bg-stone-200 justify-start h-screen p-4 font-medium gap-2"
                              style={{ height: "50px" }}
                            >
                              <FaSignOutAlt />
                              Check-out Clean Information
                            </div>
                            <div className="body p-4 gap-2">
                              <div className="text-left">
                                <div className="flex flex-col gap-y-4">
                                  <div className="flex justify-between">
                                    <span className="flex gap-1">
                                      <p className="font-semibold font-sm">
                                        Turn Clean
                                      </p>
                                      on
                                      <p className="font-semibold font-sm">
                                        {report.job_details.end_date}
                                      </p>
                                    </span>
                                    <div className="bg-green-400 p-1 rounded-full">
                                      <span className="text-xs text-white">
                                        {report.job_details.status}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex justify-start gap-1">
                                    <FaUserClock style={{ fontSize: "18px" }} />
                                    <span className="flex font-xs gap-1">
                                      {report.job_details.duration.mins} mins:
                                      started at{" "}
                                      <p className="font-semibold">
                                        {report.job_details.start_time}
                                      </p>{" "}
                                      Checked in on{" "}
                                      <p className="font-semibold">
                                        {report.job_details.end_time}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="flex justify-start gap-4">
                                    {report.images.urls
                                      .slice(0, 4)
                                      .map((imageUrl, index) => (
                                        <div
                                          key={index}
                                          className="image-container relative inline-block hover:scale-105 cursor-pointer"
                                          onClick={() => openLightbox(index)}
                                        >
                                          <img
                                            src={imageUrl}
                                            alt=""
                                            className="box-full"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              ...(index === 3 && {
                                                filter: "blur(2px)",
                                              }),
                                            }}
                                          />
                                          {index === 3 && (
                                            <div className="absolute inset-0 flex flex-col justify-center items-center">
                                              <div className="text-white text-center text-xl">
                                                +{report.images.total - 4}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ReportPaper>
                        </Stack>
                      </div>
                      <Lightbox
                        index={slideIndex}
                        open={openLightBox}
                        close={() => setOpenLightBox(false)}
                        slides={report.images.urls.map((url) => ({
                          src: url,
                        }))}
                        plugins={[Thumbnails]}
                      />
                    </div>
                  </TabPanel>
                </div>
              )}

              {/* INVENTORY DETAILS */}
              {value === 1 && (
                <TabPanel
                  key={1}
                  value={value}
                  index={1}
                  style={tabContentStyles}
                >
                  <div className="flex px-5 py-5">
                    <table className="min-w-full bg-white border border-gray-300">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b bg-stone-200">
                            Item
                          </th>
                          <th className="py-2 px-4 border-b bg-stone-200">
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(report.inventory).map(
                          ([item, quantity]) => (
                            <tr key={item}>
                              <td className="py-2 px-4 border-b text-center">
                                {item}
                              </td>
                              <td className="py-2 px-4 border-b text-center">
                                {quantity}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>

                    <div className="flex-1 p-4"></div>
                  </div>
                </TabPanel>
              )}
            </Box>
          </div>
          {/* Footer */}
          <div className="py-3 px-4 flex justify-end gap-2 -pt-2 border-t-2">
            <div className="pt-3">
              <Button
                className="bottom-3"
                size="small"
                variant="outlined"
                color="success"
                onClick={exportToPDF}
              >
                Export as PDF
              </Button>
            </div>
            <div className="pt-3">
              <Button
                className="bottom-3"
                size="small"
                variant="outlined"
                color="info"
                onClick={handleModalClose}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export default ViewJobReport;
