import React, { createContext, useContext, useState } from "react";

const JobListStateContext = createContext();

export const JobListContextProvider = ({ children }) => {
  const [openFinishJobModal, setOpenFinishJobModal] = useState(false);
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [jobData, setJobData] = useState({});
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedJobReport, setSelectedJobReport] = useState(null);

  return (
    <JobListStateContext.Provider
      value={{
        openFinishJobModal,
        setOpenFinishJobModal,
        jobData,
        setJobData,
        selectedJob,
        setSelectedJob,
        openAddNoteModal,
        setOpenAddNoteModal,
        openReportModal,
        setOpenReportModal,
        selectedJobReport,
        setSelectedJobReport,
      }}
    >
      {children}
    </JobListStateContext.Provider>
  );
};

export const useJobListStateContext = () => useContext(JobListStateContext);
