import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import { useStateContext } from "../../contexts/ContextProvider";
function ListingTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ListingTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const theme = createTheme({
  palette: {
    primary: green,
    secondary: {
      main: "#4ac299",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#FFF",
    },
  },
});
const AddInventoryItemStock = ({
  setOpenAddStockModal,
  setInventoryData,
  selectedItem,
}) => {
  const { isAdmin } = useStateContext();
  const [value, setValue] = useState(0);
  const [didSubmit, setDidSubmit] = useState(false);
  const [values, setValues] = useState({ quantity: 0 });
  const [errors, setErrors] = useState({});
  const [currentValue, setCurrentValue] = useState({
    quantity: selectedItem.quantity,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleInventoryFieldChange = (event, name) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: Number(event.target.value),
    }));
    setCurrentValue((prevValues) => ({
      ...prevValues,
      [name]: Number(event.target.value) + selectedItem.quantity,
    }));
  };
  const handleModalClose = () => {
    setOpenAddStockModal(false);
  };
  const getInventoryItems = () => {
    axiosClient
      .get(`/inventory`)
      .then(({ data }) => {
        setInventoryData(data.inventory_items);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };
  const handleSubmit = () => {
    axiosClient
      .put(`/inventory/${selectedItem.id}/restock`, values)
      .then(() => {
        getInventoryItems();
        setOpenAddStockModal(false);
        enqueueSnackbar("Successfully Inserted Stock", {
          variant: "success",
        });
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };
  const tabContentStyles = {
    transition: "opacity 0.3s ease-in-out",
    opacity: value === 0 ? 1 : 0, // Show "Property Details" tab content if it's selected
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
        <div className="relative bg-white w-full max-w-7xl rounded-lg shadow-lg">
          {/* Modal Header */}
          {/* Header */}
          <div className="bg-gray-100 py-2 px-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
                Add Inventory Stocks
              </h2>
              <button onClick={handleModalClose} className={`text-gray-500`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Modal Body */}
          <div className="max-h-[60vh] overflow-y-auto p-6">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="Inventory Tabs"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: green,
                    },
                  }}
                >
                  <Tab label="Inventory Details" />
                </Tabs>
              </Box>
              {value === 0 && (
                <TabPanel
                  key={0}
                  value={value}
                  index={0}
                  style={tabContentStyles}
                >
                  <div className="flex p-4">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Item Name"
                          value={selectedItem.name}
                          onChange={(e) =>
                            handleInventoryFieldChange(e, "name")
                          }
                          fullWidth
                          error={errors.name}
                          disabled
                          helperText={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Restock Notification Threshold"
                          value={selectedItem.restock_threshold}
                          onChange={(e) =>
                            handleInventoryFieldChange(e, "restock_threshold")
                          }
                          fullWidth
                          error={errors.restock_threshold}
                          helperText={errors.restock_threshold}
                          disabled
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="flex p-4">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Current Quantity "
                          value={currentValue.quantity}
                          // onChange={(e) => handleInventoryFieldChange(e, "quantity")}
                          fullWidth
                          error={errors.quantity}
                          helperText={errors.quantity}
                          type="number"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Add stock"
                          // value={value}
                          onChange={(e) =>
                            handleInventoryFieldChange(e, "quantity")
                          }
                          fullWidth
                          error={errors.quantity}
                          helperText={errors.quantity}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </TabPanel>
              )}
            </Box>
          </div>
          {/* Footer */}
          <div className="my-2 py-3 px-4 flex justify-end gap-2 -pt-2">
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="info"
              onClick={handleModalClose}
              disabled={didSubmit}
            >
              Back
            </Button>

            {isAdmin && (
              <>
                <Button
                  className="bottom-3"
                  size="small"
                  variant="outlined"
                  color="success"
                  onClick={handleSubmit}
                  disabled={didSubmit}
                  startIcon={
                    didSubmit ? (
                      <CircularProgress size={12} color="inherit" />
                    ) : (
                      <></>
                    )
                  }
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}
export default AddInventoryItemStock;
