import React, { createContext, useContext, useState } from "react";

const JobReportStateContext = createContext();

export const JobReportsContextProvider = ({ children }) => {
  const [openJobReportModal, setOpenJobReportModal] = useState(false);
  const [jobReportData, setJobReportData] = useState(false);
  const [selectedJobReport, setSelectedJobReport] = useState(null);

  return (
    <JobReportStateContext.Provider
      value={{
        openJobReportModal,
        setOpenJobReportModal,
        selectedJobReport,
        setSelectedJobReport,
        jobReportData,
        setJobReportData
      }}
    >
      {children}
    </JobReportStateContext.Provider>
  );
};

export const useJobReportStateContext = () => useContext(JobReportStateContext);
