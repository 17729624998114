import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { BiSolidEditAlt } from "react-icons/bi";
import { GoSync, GoEye } from "react-icons/go";
import { BsHouseAdd } from "react-icons/bs";
import { propertiesGrid } from "../../data/propertiesData";
import { Header } from "../../components";
import Button from "@mui/material/Button";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import { usePropertyListStateContext } from "../../contexts/PropertyListContextProvider";
import SyncProperties from "../Modals/SyncProperties.jsx";
import { useStateContext } from "../../contexts/ContextProvider";
import PuffLoader from "react-spinners/PuffLoader";
import { TypeAnimation } from "react-type-animation";
import ManageProperty from "../Modals/ManageProperty";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import AddProperty from "../Modals/AddProperty.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";

const Properties = () => {
  const {
    propertyData,
    setPropertyData,
    openEditModal,
    setOpenEditModal,
    selectedProperty,
    setSelectedProperty,
    openSyncModal,
    setOpenSyncModal,
    openAddModal,
    setOpenAddModal,
  } = usePropertyListStateContext();
  const { type: paramType } = useParams();

  const [type, setType] = useState(paramType);

  useEffect(() => {
    setType(paramType);
  }, [paramType]);

  useEffect(() => {
    if (type !== paramType) {
      window.location.reload();
    }
  }, [type, paramType]);

  const { isSyncing, setIsSyncing, isAdmin } = useStateContext();

  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true, allowEditing: true };

  const ActionButton = ({ title, customFunc, icon, color }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-lg rounder-full p-3 hover:bg-light-gray"
      >
        <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );

  const propertyListButtons = (props) => (
    <div className="flex items-center justify-center gap-2">
      {type !== "other" && (
        <ActionButton
          title="View on Hospitable"
          customFunc={() => handleView(props)}
          icon={<GoEye />}
          color="#4d4c5c"
        />
      )}
      <ActionButton
        title="Manage Property"
        customFunc={() => handleEdit(props)}
        icon={<BiSolidEditAlt />}
        color="#4169e1"
      />
      {type === "other" && (
        <ActionButton
          title="Delete"
          customFunc={() => {}}
          icon={<RiDeleteBin6Line />}
          color="#e3242b"
        />
      )}
    </div>
  );

  const handleView = (data) => {
    window.open(data.link);
  };

  const handleEdit = (data) => {
    axiosClient
      .get(`/properties/${type}/${data.id}`)
      .then(({ data }) => {
        setSelectedProperty(data.properties);
        setOpenEditModal(true);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const handleSync = () => {
    setOpenSyncModal(true);
  };

  const handleAdd = () => {
    setOpenAddModal(true);
  };

  useEffect(() => {
    axiosClient
      .get(`/properties/${type}`)
      .then(({ data }) => {
        setPropertyData(data.properties);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  }, [type]);

  // Function to call when the sync modal is closed
  const handleModalSync = () => {
    // Close the modal
    setOpenSyncModal(false);
    setIsSyncing(true);

    // Call your API or perform any other actions here
    axiosClient
      .post("/sync-properties")
      .then(({ data }) => {
        setIsSyncing(false);
        enqueueSnackbar("Successfully synced data with Hospitable", {
          variant: "success",
        });
        setPropertyData(data.properties);
      })
      .catch((err) => {
        setIsSyncing(false);
        const response = err.response;
        enqueueSnackbar("Failed to sync data with Hospitable", {
          variant: "error",
        });
      });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Lists" title="Properties" />
      {isAdmin && type === "airbnb" && (
        <div className="flex">
          <TooltipComponent
            content="Sync properties data from Hospitable"
            position="BottomCenter"
          >
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="success"
              onClick={handleSync}
              disabled={isSyncing}
              startIcon={
                isSyncing ? (
                  <CircularProgress size={12} color="inherit" />
                ) : (
                  <GoSync />
                )
              }
            >
              Sync Data
            </Button>
          </TooltipComponent>
        </div>
      )}

      {isAdmin && type === "other" && (
        <div className="flex">
          <TooltipComponent
            content="Manually add properties"
            position="BottomCenter"
          >
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="success"
              onClick={handleAdd}
              disabled={isSyncing}
              startIcon={<BsHouseAdd />}
            >
              Add Property
            </Button>
          </TooltipComponent>
        </div>
      )}

      {/* if data is not syncing */}
      {!isSyncing && (
        <GridComponent
          dataSource={propertyData}
          width="auto"
          allowPaging
          allowSorting
          pageSettings={{ pageCount: 5, pageSize: 10 }}
          editSettings={editing}
          toolbar={toolbarOptions}
          // statelessTemplates={["directiveTemplates"]}
          allowSelection={false}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {propertiesGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
            <ColumnDirective
              key="actions"
              headerText="Actions"
              width="100"
              textAlign="Center"
              template={propertyListButtons}
            />
          </ColumnsDirective>
          <Inject services={[Search, Page, Toolbar, Sort]} />
        </GridComponent>
      )}

      {/* if sync is ongoing */}
      {isSyncing && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "180px",
            }}
          >
            <div className="mb-10">
              <TypeAnimation
                sequence={[
                  "Syncing data from Hospitable...",
                  500,
                  "Please wait...",
                  500,
                ]}
                style={{ fontSize: "24px", color: "#9CA3AF" }}
                repeat={Infinity}
                omitDeletionAnimation={true}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "300px",
            }}
          >
            <PuffLoader color="#16a34a" />
          </div>
        </>
      )}
      {openEditModal && (
        <ManageProperty
          setOpenEditModal={setOpenEditModal}
          property={selectedProperty}
          setPropertyData={setPropertyData}
          type={type}
        />
      )}
      {openSyncModal && (
        <SyncProperties
          setOpenSyncModal={setOpenSyncModal}
          handleModalSync={handleModalSync}
        />
      )}
      {openAddModal && (
        <AddProperty
          setOpenAddModal={setOpenAddModal}
          setPropertyData={setPropertyData}
          type={type}
        />
      )}
    </div>
  );
};
export default Properties;
