import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import axiosClient from "../../axios-client";

import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

const EditUser = ({ setOpenEditModal, user, setUserData }) => {
  const cardData = [
    {
      title: "Card 1",
      content: "Content for Card 1",
    },
    {
      title: "Card 2",
      content: "Content for Card 2",
    },
    {
      title: "Card 3",
      content: "Content for Card 3",
    },
    // Add more card data as needed
  ];

  const [didSubmit, setDidSubmit] = useState(false);
  const [didActivate, setDidActivate] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [rolesSelectItems, setRoleSelectItems] = useState([]);

  // input field state declaration

  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  // input field error state declarations
  const [emailError, setEmailError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [firstNameError, setFirstNameError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [lastNameError, setLastNameError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [roleError, setRoleError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [passwordError, setPasswordError] = useState({
    isError: false,
    errorMessage: "",
  });

  // input field change event handlers

  useEffect(() => {
    axiosClient.get("/roles").then(({ data }) => {
      let rolesData = Object.entries(data.roles).map(([label, value]) => ({
        label,
        value,
      }));

      setRoleSelectItems(rolesData);
      setRole(user.roleDetails.id);
    });
  }, []);

  const handleEmailChange = (event) => {
    setEmailError({
      isError: false,
      errorMessage: "",
    });

    setEmail(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstNameError({
      isError: false,
      errorMessage: "",
    });

    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastNameError({
      isError: false,
      errorMessage: "",
    });

    setLastName(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRoleError({
      isError: false,
      errorMessage: "",
    });

    setRole(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordError({
      isError: false,
      errorMessage: "",
    });

    setPassword(event.target.value);
  };

  // input field validations

  const validateRole = () => {
    if (role.length === 0) {
      setRoleError({
        isError: true,
        errorMessage: "This field cannot be empty",
      });
    }
  };

  const validateFirstName = () => {
    if ((firstName?.trim() ?? "").length === 0) {
      setFirstNameError({
        isError: true,
        errorMessage: "This field cannot be empty",
      });
    }

    if (firstName.length > 60) {
      setFirstNameError({
        isError: true,
        errorMessage: "This field can only up to 60 characters long",
      });
    }
  };

  const validateLastName = () => {
    if ((lastName?.trim() ?? "").length === 0) {
      setLastNameError({
        isError: true,
        errorMessage: "This field cannot be empty",
      });
    }

    if (lastName.length > 60) {
      setLastNameError({
        isError: true,
        errorMessage: "This field can only up to 60 characters long",
      });
    }
  };

  const validateEmail = () => {
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError({
        isError: true,
        errorMessage: "This field should be a valid email",
      });
    }
  };

  const validatePassword = () => {
    let passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/i;
    if (password && !passwordRegex.test(password)) {
      setPasswordError({
        isError: true,
        errorMessage:
          "Password should be 8 characters minimum length, should contain at least 1 lower case, 1 upper case, 1 number, and 1 special character ",
      });
    }
  };

  const submitForm = () => {
    // show error toast when form validation fails
    if (
      emailError.isError ||
      firstNameError.isError ||
      lastNameError.isError ||
      roleError.isError ||
      passwordError.isError
    ) {
      enqueueSnackbar("Please address all input field errors", {
        variant: "error",
      });
    }
    // notify the user when there are no fields to update
    else if (
      email === user.email &&
      firstName === user.first_name &&
      lastName === user.last_name &&
      role === user.roleDetails.id &&
      password === ""
    ) {
      enqueueSnackbar("There are no fields to update", {
        variant: "info",
      });
    }
    // actual api call after validation
    else {
      setDidSubmit(true);
      let payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        role_id: role,
        password: password,
      };

      axiosClient
        .put(`user/${user.id}/update`, payload)
        .then(() => {
          setDidSubmit(false);
          setOpenEditModal(false);

          getUsers();
          setLastName("");
          setFirstName("");
          setEmail("");
          setRole("");
          setPassword("");
          setRoleSelectItems([]);
          enqueueSnackbar("User has been updated", {
            variant: "success",
          });
        })
        .catch((err) => {
          const response = err.response;
          setDidSubmit(false);
          setOpenEditModal(false);
          setLastName("");
          setFirstName("");
          setEmail("");
          setRole("");
          setPassword("");
          setRoleSelectItems([]);
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        });
    }
  };

  const activateUser = () => {
    setDidActivate(true);

    axiosClient.put(`user/${user.id}/activate`).then(() => {
      // reset states
      setDidSubmit(false);
      setOpenEditModal(false);

      getUsers();
      setLastName("");
      setFirstName("");
      setEmail("");
      setRole("");
      setPassword("");
      setRoleSelectItems([]);
      enqueueSnackbar("User status has been changed to Active", {
        variant: "success",
      });
    });
  };

  const deactivateUser = () => {
    setDidActivate(true);

    axiosClient.put(`user/${user.id}/deactivate`).then(() => {
      // reset states
      setDidSubmit(false);
      setOpenEditModal(false);

      getUsers();
      setLastName("");
      setFirstName("");
      setEmail("");
      setRole("");
      setPassword("");
      setRoleSelectItems([]);
      enqueueSnackbar("User has been changed to Inactive", {
        variant: "success",
      });
    });
  };

  const getUsers = () => {
    axiosClient.get("/users").then(({ data }) => {
      setUserData(data.users);
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
      <div className="bg-white w-full max-w-6xl max-h-6xl rounded-lg shadow-lg overflow-y-auto">
        {/* Modal Header */}
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              Edit User
            </h2>
            <button
              onClick={() => setOpenEditModal(false)}
              className={`text-gray-500 ${
                !didSubmit ? "hover:text-black" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Modal Body */}
        <p className="text-gray-700 font-semibold tracking-tight text-slate-900 pl-4 pt-4">
          Basic Information
        </p>

        <div className="flex p-4">
          <Grid container spacing={3}>
            {/* Row 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                error={emailError.isError}
                helperText={emailError.isError ? emailError.errorMessage : ""}
                label="Email"
                defaultValue={email}
                onBlur={validateEmail}
                onChange={handleEmailChange}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                label="Is Verified?"
                disabled
                defaultValue={user.is_email_verified}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className="flex p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="First Name"
                error={firstNameError.isError}
                helperText={
                  firstNameError.isError ? firstNameError.errorMessage : ""
                }
                defaultValue={firstName}
                onBlur={validateFirstName}
                onChange={handleFirstNameChange}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Last Name"
                error={lastNameError.isError}
                helperText={
                  lastNameError.isError ? lastNameError.errorMessage : ""
                }
                defaultValue={lastName}
                onBlur={validateLastName}
                onChange={handleLastNameChange}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <TextField
                label="Status"
                disabled
                defaultValue={user.status}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className="flex p-4">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select // tell TextField to render select
                label="Role"
                size="small"
                value={role}
                fullWidth
                onBlur={validateRole}
                onChange={handleRoleChange}
              >
                {rolesSelectItems.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="New Password"
                size="small"
                type="password"
                fullWidth
                onBlur={validatePassword}
                onChange={handlePasswordChange}
                error={passwordError.isError}
                helperText={
                  passwordError.isError ? passwordError.errorMessage : ""
                }
              />
            </Grid>
          </Grid>
        </div>

        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="info"
            disabled={didSubmit || didActivate}
            onClick={() => setOpenEditModal(false)}
          >
            Back
          </Button>
          {(user.status === "Unverified" || user.status === "Inactive") && (
            <Button
              className="bottom-3"
              size="small"
              color="secondary"
              variant="outlined"
              disabled={didSubmit || didActivate}
              startIcon={
                didActivate ? <CircularProgress size={12} color="inherit" /> : ""
              }
              onClick={activateUser}
            >
              Activate
            </Button>
          )}
          {user.status === "Active" && (
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="error"
              disabled={didSubmit || didActivate}
              startIcon={
                didActivate ? <CircularProgress size={12} color="inherit" /> : ""
              }
              onClick={deactivateUser}
            >
              Deactivate
            </Button>
          )}
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="success"
            disabled={didSubmit || didActivate}
            startIcon={
              didSubmit ? <CircularProgress size={12} color="inherit" /> : ""
            }
            onClick={submitForm}
          >
            Update User
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
