const actionLog = (props) => {
  const color = props.action === "Pick" ? "text-red-500" : "text-green-500";
  return <span className={`${color} bold`}>{props.action}</span>;
};

const quantityLog = (props) => {
  const isPickAction = props.action === "Pick";
  const sign = isPickAction ? "-" : "+";
  const color = isPickAction ? "text-red-500" : "text-green-500";

  return <span className={`${color} bold`}>{`${sign}${" "}${props.quantity}`}</span>;
};

const gridInventoryNameWithMessage = (props) => (
    <div className="flex flex-col">
      <p className="font-semibold font-sm">{props.name}</p>
      {props.needs_restock && (
      <p className="font-xs text-pink-500">Needs restock</p>)}
  </div>
);

export const inventoryGrid = [
  {
    field: "name",
    template: gridInventoryNameWithMessage,
    headerText: "Item Name",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "quantity",
    headerText: "Quantity",
    width: "170",
    textAlign: "Left",
  },
];

export const inventoryReportsGrid = [
  {
    field:"action",
    template: actionLog,
    headerText: "Action",
    width: "150",
    textAlign: "Left",
  },
  {
    field:"quantity",
    template: quantityLog,
    headerText: "Quantity",
    width: "150",
    textAlign: "Left",
  },
  {
    field:"cleaning_report",
    headerText: "Cleaning Report",
    width: "150",
    textAlign: "Left",
  },
  {
    field:"user",
    headerText: "Triggered By",
    width: "150",
    textAlign: "Left",
  },
  {
    field:"date",
    headerText: "Date",
    width: "150",
    textAlign: "Left",
  }
];