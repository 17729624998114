import React, { useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { BiSolidEditAlt } from "react-icons/bi";
import { GoSync, GoEye } from "react-icons/go";
import { jobReportsGrid } from "../../data/jobsData.js";
import { Header } from "../../components";
import Button from "@mui/material/Button";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import EditUser from "../Modals/EditUser";
import { useJobReportStateContext } from "../../contexts/JobReportsContextProvider.js";
import { TbReportSearch } from "react-icons/tb";
import SyncProperties from "../Modals/SyncProperties.jsx";
import { useStateContext } from "../../contexts/ContextProvider";
import PuffLoader from "react-spinners/PuffLoader";
import { TypeAnimation } from "react-type-animation";
import ManageProperty from "../Modals/ManageProperty";
import { CircularProgress } from "@mui/material";
import ViewJobReport from "../Modals/ViewJobReport.jsx";
import { useLocation } from "react-router-dom";

const JobReports = () => {
  const {
    jobReportData,
    setJobReportData,
    openJobReportModal,
    setOpenJobReportModal,
    selectedJobReport,
    setSelectedJobReport,
  } = useJobReportStateContext();

  const toolbarOptions = ["Search"];
  const location = useLocation();
  const editing = { allowDeleting: true, allowEditing: true };

  const ActionButton = ({ title, customFunc, icon, color }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-lg rounder-full p-3 hover:bg-light-gray"
      >
        <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );

  const propertyListButtons = (props) => (
    <div className="flex items-center justify-center gap-2">
      <ActionButton
        title="View Report"
        customFunc={() => viewReport(props)}
        icon={<TbReportSearch />}
        color="#4d4c5c"
      />
    </div>
  );

  const viewReport = (data) => {
    axiosClient
      .get(`/reports/jobs/${data.id}`)
      .then(({ data }) => {
        setSelectedJobReport(data.cleaning_report);
        setOpenJobReportModal(true);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    axiosClient
      .get("/reports/jobs")
      .then(({ data }) => {
        setJobReportData(data.cleaning_reports);

        const queryParams = new URLSearchParams(location.search);
        const reportId = queryParams.get("report_id");
        if (reportId) {
          axiosClient
            .get(`/reports/jobs/${reportId}`)
            .then(({ data }) => {
              setSelectedJobReport(data.cleaning_report);
              setOpenJobReportModal(true);
            })
            .catch((err) => {
              const response = err.response;
              enqueueSnackbar(response.data.message, {
                variant: "error",
              });
            });
        }
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  }, [setJobReportData]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Reports" title="Job Reports" />

      {/* if data is not syncing */}

      <GridComponent
        dataSource={jobReportData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        statelessTemplates={["directiveTemplates"]}
        allowSelection={false}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {jobReportsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
          <ColumnDirective
            key="actions"
            headerText="Actions"
            width="100"
            textAlign="Center"
            template={propertyListButtons}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Sort]} />
      </GridComponent>

      {openJobReportModal && (
        <ViewJobReport
          setOpenJobReportModal={setOpenJobReportModal}
          report={selectedJobReport}
        />
      )}
    </div>
  );
};
export default JobReports;
