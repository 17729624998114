import React, { createContext, useContext, useState } from "react";

const UserListStateContext = createContext();

export const UserListContextProvider = ({ children }) => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <UserListStateContext.Provider
      value={{
        openViewModal,
        setOpenViewModal,
        openEditModal,
        setOpenEditModal,
        openDeleteModal,
        setOpenDeleteModal,
        openAddModal,
        setOpenAddModal,
        userData,
        setUserData,
        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </UserListStateContext.Provider>
  );
};

export const useUserListStateContext = () => useContext(UserListStateContext);
