import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { Notification, UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {
    isClicked,
    setActiveMenu,
    handleClick,
    screenSize,
    setScreenSize,
    user,
    setToken,
    setIsAdmin,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const logout = () => {
    setToken(null);
    setIsAdmin(false);
    localStorage.clear();
  };

  return (
    <div className="flex justify-between p-2 md:mx-6 relative">
      <NavButton
        title="Menu"
        customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color="#16a34a"
        icon={<AiOutlineMenu />}
      />

      <div className="flex gap-4">
        {/* <NavButton
          title="Cart"
          // customFunc={() => handleClick('cart')}
          customFunc={() => {}}
          color="blue"
          icon={<FiShoppingCart />}
        />
        <NavButton
          title="Chat"
          dotColor="#03C9D7"
          // customFunc={() => handleClick('chat')}
          customFunc={() => {}}
          color="blue"
          icon={<BsChatLeft />}
        /> */}
        {/* <NavButton
          title="Notifications"
          dotColor="#03C9D7"
          customFunc={() => handleClick("notification")}
          color="#16a34a"
          icon={<RiNotification3Line />}
        /> */}
        <div
          className="flex items-center gap-2"
          // TODO:
          // onClick={() => handleClick("userProfile")}
        >
          <p>
            <span className="text-gray-400 text-14">Hi,</span>{" "}
            <span className="text-gray-400 font-bold ml-1 text-14">
              {user.first_name}!
            </span>
          </p>
        </div>
        <div
          className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
          // TODO:
          // onClick={() => handleClick("userProfile")}
          onClick={logout}
        >
          <RiLogoutBoxRLine className="text-gray-400 text-xl" />
        </div>
        {isClicked.notification && <Notification />}
        {isClicked.userProfile && <UserProfile />}
      </div>
    </div>
  );
};

export default Navbar;
