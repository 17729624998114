import React, { createContext, useContext, useState } from "react";

const PropertyListStateContext = createContext();

export const PropertyListContextProvider = ({ children }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);

  return (
    <PropertyListStateContext.Provider
      value={{
        openEditModal,
        setOpenEditModal,
        openSyncModal,
        setOpenSyncModal,
        propertyData,
        setPropertyData,
        selectedProperty,
        setSelectedProperty,
        openAddModal,
        setOpenAddModal,
      }}
    >
      {children}
    </PropertyListStateContext.Provider>
  );
};

export const usePropertyListStateContext = () => useContext(PropertyListStateContext);
