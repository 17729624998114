import React, { createContext, useContext, useState } from "react";

const InventoryListStateContext = createContext();

export const InventoryListContextProvider = ({ children }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [inventoryData, setInventoryData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [updatedInventory, setUpdatedInventory] = useState({});
  const [inventoryReportsData, setInventoryReportsData] = useState([]);
  const [openThresholdEditModal, setOpenThresholdEditModal] = useState(false);
  const [openAddStockModal, setOpenAddStockModal] = useState(false);

  return (
    <InventoryListStateContext.Provider
      value={{
        openEditModal,
        setOpenEditModal,
        openDeleteModal,
        setOpenDeleteModal,
        openAddModal,
        setOpenAddModal,
        inventoryData,
        setInventoryData,
        selectedItem,
        setSelectedItem,
        openThresholdEditModal,
        setOpenThresholdEditModal,
        openAddStockModal,
        setOpenAddStockModal,
        inventoryReportsData,
        setInventoryReportsData,
      }}
    >
      {children}
    </InventoryListStateContext.Provider>
  );
};

export const useInventoryListStateContext = () => useContext(InventoryListStateContext);
