import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  Dashboard,
  Inventory,
  InventoryReports,
  JobReports,
  Jobs,
  Profile,
  Properties,
  PropertyReports,
  UserReports,
  Users,
} from "./pages/index.jsx";
import DefaultLayout from "./pages/DefaultLayout.jsx";
import { UserListContextProvider } from "./contexts/UserListContextProvider.js";
import { PropertyListContextProvider } from "./contexts/PropertyListContextProvider.js";
import { JobListContextProvider } from "./contexts/JobListContextProvider.js";
import { PropertyReportsContextProvider } from "./contexts/PropertyReportsContextProvider.js";
import { JobReportsContextProvider } from "./contexts/JobReportsContextProvider.js";
import GuestLayout from "./pages/GuestLayout.jsx";
import Login from "./pages/Login/Login.jsx";
import ForgotPassword from "./pages/Login/ForgotPassword.jsx";
import SetPassword from "./pages/Login/SetPassword.jsx";
import ResetPassword from "./pages/Login/ResetPassword.jsx";
import { InventoryListContextProvider } from "./contexts/InventoryListContextProvider.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/properties/:type",
        element: (
          <PropertyListContextProvider>
            <Properties />
          </PropertyListContextProvider>
        ),
      },
      {
        path: "/users",
        element: (
          <UserListContextProvider>
            <Users />
          </UserListContextProvider>
        ),
      },
      {
        path: "/jobs",
        element: (
          <JobListContextProvider>
            <Jobs />
          </JobListContextProvider>
        ),
      },
      {
        path: "/inventory",
        element: (
          <InventoryListContextProvider>
            <Inventory />
          </InventoryListContextProvider>
        ),
      },
      {
        path: "/property-reports",
        element: (
          <PropertyReportsContextProvider>
            <PropertyReports />
          </PropertyReportsContextProvider>
        ),
      },
      {
        path: "/job-reports",
        element: (
          <JobReportsContextProvider>
            <JobReports />
          </JobReportsContextProvider>
        ),
      },
      {
        path: "/inventory-reports",
        element: (
          <InventoryListContextProvider>
            <InventoryReports />
          </InventoryListContextProvider>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/set-password",
        element: <SetPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  //   {
  //     path: "*",
  //     element: <NotFound />,
  //   },
]);

export default router;
