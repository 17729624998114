import { Button, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import axiosClient from "../../axios-client";

const DeleteUser = ({ setOpenDeleteModal, user, setUserData }) => {
  const [didSubmit, setDidSubmit] = useState(false);

  const handleSubmit = () => {
    setDidSubmit(true);

    axiosClient
      .delete(`/user/${user.id}`)
      .then(({}) => {
        setDidSubmit(false);
        setOpenDeleteModal(false);
        enqueueSnackbar("User has been deleted", {
          variant: "success",
        });

        // overwrite setData and setFiltered data states
        axiosClient.get("/users").then(({ data }) => {
          setUserData(data.users);
        });
      })
      .catch((err) => {
        const response = err.response;
        setDidSubmit(false);
        setOpenDeleteModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white w-11/12 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              Delete User
            </h2>
            <button
              disabled={didSubmit}
              onClick={() => setOpenDeleteModal(false)}
              className={`text-gray-500 ${
                !didSubmit ? "hover:text-black" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="p-4">
          <h4
            className="text-base"
            style={{
              textAlign: "left",
              color: "#555",
              display: "inline",
            }}
          >
            Are you sure you want to delete{" "}
          </h4>
          <h5
            className="text-sm"
            style={{
              textAlign: "left",
              color: "#555",
              display: "inline",
            }}
          >
            <u>
              {user.name} ({user.email})
            </u>
          </h5>
          <h4
            className="text-base"
            style={{
              textAlign: "left",
              color: "#555",
              display: "inline",
            }}
          >
            {" "}
            from the database?
          </h4>
        </div>

        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setOpenDeleteModal(false)}
            disabled={didSubmit}
          >
            Back
          </Button>
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="success"
            onClick={handleSubmit}
            disabled={didSubmit}
            startIcon={
              didSubmit ? <CircularProgress size={12} color="inherit" /> : ""
            }
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
