import { Button, CircularProgress, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import axiosClient from "../../axios-client";

const AddNote = ({ setOpenAddNoteModal, job, setJobData, jobListFilter }) => {
  const [didSubmit, setDidSubmit] = useState(false);
  const [jobNote, setJobNote] = useState(null);
  const [noteError, setNoteError] = useState({
    isError: false,
    errorMessage: "",
  });

  const handleSubmit = () => {
    if (!jobNote) {
      setNoteError({
        isError: true,
        errorMessage: "Field should not be empty",
      });
    } else {
      setDidSubmit(true);

      let payload = {
        note: jobNote,
      };

      axiosClient
        .put(`/jobs/${job.id}/add-note`, payload)
        .then(({}) => {
          setDidSubmit(false);
          setOpenAddNoteModal(false);
          enqueueSnackbar("Job has been updated", {
            variant: "success",
          });

          let params = {
            filter: jobListFilter,
          };
          axiosClient
            .get("/jobs", {
              params: params,
            })
            .then(({ data }) => {
              setJobData(data.jobs);
            })
            .catch((err) => {
              const response = err.response;
              enqueueSnackbar(response.data.message, {
                variant: "error",
              });
            });
        })
        .catch((err) => {
          const response = err.response;
          setDidSubmit(false);
          setOpenAddNoteModal(false);
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        });
    }
  };

  const handleNoteFieldChange = (event) => {
    if (noteError.isError) {
      setNoteError({
        isError: false,
        errorMessage: "",
      });
    }

    setJobNote(event.target.value);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white w-11/12 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              Job ID #{job.id} - Add Note
            </h2>
            <button
              disabled={didSubmit}
              onClick={() => setOpenAddNoteModal(false)}
              className={`text-gray-500 ${
                !didSubmit ? "hover:text-black" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="p-4">
          <TextField
            label="Job Notes"
            multiline
            fullWidth
            onChange={handleNoteFieldChange}
            error={noteError.isError}
            helperText={noteError.isError ? noteError.errorMessage : ""}
          />
        </div>

        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setOpenAddNoteModal(false)}
            disabled={didSubmit}
          >
            Back
          </Button>
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="success"
            onClick={handleSubmit}
            disabled={didSubmit}
            startIcon={
              didSubmit ? <CircularProgress size={12} color="inherit" /> : ""
            }
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
