import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { BiSolidEditAlt } from "react-icons/bi";
import { TbToiletPaper } from "react-icons/tb";
import { AiFillControl } from "react-icons/ai";
import { MdAddToPhotos } from "react-icons/md";

import { inventoryGrid } from "../../data/inventoryData";
import { Header } from "../../components";
import Button from "@mui/material/Button";
import axiosClient from "../../axios-client";
import DeleteUser from "../Modals/DeleteUser";
import { enqueueSnackbar } from "notistack";
import { useInventoryListStateContext } from "../../contexts/InventoryListContextProvider";
import EditInventoryItem from "../Modals/EditInventoryItem";
import AddInventoryItem from "../Modals/AddInventoryItem";
import EditItemThreshold from "../Modals/EditInventoryThreshold";
import AddInventoryItemStock from "../Modals/AddInventoryItemStock";

const Inventory = () => {
  const {
    inventoryData,
    setInventoryData,
    openAddModal,
    setOpenAddModal,
    openDeleteModal,
    setOpenDeleteModal,
    openEditModal,
    setOpenEditModal,
    selectedItem,
    setSelectedItem,
    openThresholdEditModal,
    setOpenThresholdEditModal,
    openAddStockModal,
    setOpenAddStockModal,
  } = useInventoryListStateContext();

  const toolbarOptions = ["Search"];
  const editing = { allowDeleting: true, allowEditing: true };

  const ActionButton = ({ title, customFunc, icon, color }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-lg rounder-full p-3 hover:bg-light-gray"
      >
        <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );

  const userListButtons = (props) => (
    <>
      <div className="flex items-center justify-center gap-1">
        <div>
          <ActionButton
            title="Edit Threshold "
            customFunc={() => handleEditThreshold(props)}
            icon={<AiFillControl />}
            color="#FFC107"
          />
        </div>
        <div>
          <ActionButton
            title="Add Stock"
            customFunc={() => handleAddStock(props)}
            icon={<MdAddToPhotos />}
            color="#28a745"
          />
        </div>
      </div>
    </>
  );
  const handleEdit = (data) => {
    setSelectedItem(data);
    setOpenEditModal(true);
  };

  const handleAdd = () => {
    setOpenAddModal(true);
  };

  const handleEditThreshold = (data) => {
    setSelectedItem(data);
    setOpenThresholdEditModal(true);
  };
  const handleAddStock = (data) => {
    setSelectedItem(data);
    setOpenAddStockModal(true);
  };
  useEffect(() => {
    axiosClient
      .get("/inventory")
      .then(({ data }) => {
        setInventoryData(data.inventory_items);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Lists" title="Inventory" />
      <div className="flex">
        <TooltipComponent content="Add User" position="BottomCenter">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            startIcon={<TbToiletPaper />}
            color="success"
            onClick={handleAdd}
          >
            Add Item
          </Button>
        </TooltipComponent>
      </div>
      <GridComponent
        dataSource={inventoryData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        statelessTemplates={["directiveTemplates"]}
        allowSelection={false}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {inventoryGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}

          <ColumnDirective
            key="actions"
            headerText="Actions"
            width="100"
            textAlign="Center"
            template={userListButtons}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Sort]} />
      </GridComponent>

      {openDeleteModal && (
        <DeleteUser
          setOpenDeleteModal={setOpenDeleteModal}
          user={selectedItem}
          setUserData={setInventoryData}
        />
      )}
      {openEditModal && (
        <EditInventoryItem
          setOpenEditModal={setOpenEditModal}
          user={selectedItem}
          setUserData={setInventoryData}
        />
      )}
      {openAddModal && (
        <AddInventoryItem
          setOpenAddModal={setOpenAddModal}
          setInventoryData={setInventoryData}
        />
      )}
      {openThresholdEditModal && (
        <EditItemThreshold
          setOpenThresholdEditModal={setOpenThresholdEditModal}
          setInventoryData={setInventoryData}
          selectedItem={selectedItem}
        />
      )}
      {openAddStockModal && (
        <AddInventoryItemStock
          setOpenAddStockModal={setOpenAddStockModal}
          setInventoryData={setInventoryData}
          selectedItem={selectedItem}
        />
      )}
    </div>
  );
};
export default Inventory;
