import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Header } from "../../components";
import axiosClient from "../../axios-client";
import { TbReportSearch } from "react-icons/tb";
import ViewJobReport from "../Modals/ViewJobReport.jsx";
import { useLocation } from "react-router-dom";
import { inventoryReportsGrid } from "../../data/inventoryData.js";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { FaFilter } from "react-icons/fa";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useInventoryListStateContext } from "../../contexts/InventoryListContextProvider.js";

const InventoryReports = () => {
  const { inventoryReportsData, setInventoryReportsData } =
    useInventoryListStateContext();

  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const toolbarOptions = ["Search"];
  const editing = { allowDeleting: true, allowEditing: true };

  useEffect(() => {
    axiosClient.get("/inventory-items").then(({ data }) => {
      setInventoryItems(data.inventory_items);
    });
  }, []);

  const handleFilter = () => {
    let url = `/inventory/item/${selectedItem}`;
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };

    const formattedStartDate = startDate
      ? startDate.toLocaleDateString("en-AU", options)
      : null;
    const formattedEndDate = endDate
      ? endDate.toLocaleDateString("en-AU", options)
      : null;

    const queryParams = {
      from: formattedStartDate
        ? formattedStartDate.split("/").reverse().join("-")
        : "",
      to: formattedEndDate
        ? formattedEndDate.split("/").reverse().join("-")
        : "",
    };

    axiosClient.get(url, { params: queryParams }).then(({ data }) => {
      setInventoryReportsData(Object.values(data.inventory_logs));
    });
  };

  const handleCustomDateRangeChange = (props) => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Reports" title="Inventory Reports" />

      {/* <TextField select label="Item" size="small" fullWidth /> */}
      <div className="pb-4">
        <Grid container spacing={3}>
          {/* Row 1 */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              select // tell TextField to render select
              label="Inventory Item"
              size="small"
              onChange={(e) => setSelectedItem(e.target.value)}
              fullWidth
              defaultValue={""}
            >
              {inventoryItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <DateRangePickerComponent
              placeholder="Select a date range"
              change={handleCustomDateRangeChange}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={2}>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={handleFilter}
              disabled={!selectedItem}
              startIcon={<FaFilter />}
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </div>
      <GridComponent
        dataSource={inventoryReportsData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        allowSelection={false}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {inventoryReportsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Sort]} />
      </GridComponent>
    </div>
  );
};
export default InventoryReports;
